<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage Campaigns</div>
      </portal>
      
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :to="{ name: 'CreateCampaign'}">
        Create campaign
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
      
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.progress="{ item }">
          <span
            class="font-xs complete-status-card"
            :class="item['complete_status'] ? 'completeStatus--text' : 'incompleteStatus--text'"
          >
            {{ item['complete_status'] ? 'Complete' : 'Incomplete' }}
          </span>
        </template>
        
        <template #item.name="{ item }">
          <div class="d-flex">
            <span class="font-normal text-no-wrap">{{ item.name }}</span>
            <TooltipAction bottom message="Campaign doesn't receive calls right now" :maxWidth="180" v-if="item.warningStatus">
              <template #activator="{ on }">
                <v-icon v-on="on" class="pl-5" color="yellow">
                  $systemIcon
                </v-icon>
              </template>
            </TooltipAction>
          </div>
        </template>
        
        <template #item.country_id="{ item }">
          <span class="font-normal text-no-wrap" v-if="item['country_id']">
            {{ getCountryName(item['country_id']) }}
          </span>
        </template>
        
        <template #item.status_id="{ item }">
          <SwitchSm
            :value="getStatus(item)"
            @input="changeStatus(item)"
            :loading="loadingStatus"
            bgColor="switcherYellow"
          />
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Edit">
            <template #activator>
              <v-icon class="mr-6" @click="editCampaign(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>

          <TooltipAction bottom message="Clone campaign">
            <template #activator>
              <v-icon class="mr-6" @click="openCloneCampaignDialog(item)">$cloneIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon class="mr-6" @click="openDeleteCampaignDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Copy campaign id">
            <template #activator>
              <v-icon size="18" @click="copyId(item)">$copyIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />
    </div>

    <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="actionSuccess"
    />

    <DialogCloneCampaign
      v-model="cloneCampaignDialog"
      :campaign="selectedItem"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import SwitchSm from '@/components/controls/SwitchSm'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogCloneCampaign from '@/components/dialog/DialogCloneCampaign'
import TablePagination from '@/components/pagination/TablePagination'

import { getCampaigns, cloneCampaign, changeCampaignStatus, deleteCampaign } from '@/api/campaign-routes'
import { getOnPage, copyInStash, changeItemsAfterUpdate, parsePaginationData } from '@/helper/app-helper'
import { mapGetters } from 'vuex'
import { Types as indexTypes } from '@/store'

export default {
  name: 'ManageCampaigns',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, SwitchSm, TooltipAction, DialogConfirm, DialogCloneCampaign, TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    dialog: false,
    cloneCampaignDialog: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    options: {},
    headers: [
      { text: 'PROGRESS', value: 'progress', width: 120, sortable: false, class: 'pl-18' },
      { text: 'CAMPAIGN', value: 'name', sortId: 'byName' },
      { text: 'COUNTRY', value: 'country_id', sortId: 'byCountry' },
      { text: 'STATUS', value: 'status_id', width: 80, align: 'center', sortId: 'byStatus' },
      { text: 'ACTIONS', value: 'actions', sortable: false, width: 160, align: 'right' },
    ],
    confirmDialog: false,
    selectedItem: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
    search: null,
    actionName: null,
  }),
  computed: {
    ...mapGetters({
      getCountryName: indexTypes.getters.GET_COUNTRY_NAME_BY_ID,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadCampaigns() {
      this.loading = true

      const { success, payload, message } = await getCampaigns({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadCampaigns()
      }
      this.$vuetify.goTo(0)
    },
    editCampaign(campaign) {
      this.$router.push({ name: 'DetailCampaign', params: { slug: campaign.slug }})
    },
    openCloneCampaignDialog(campaign) {
      this.selectedItem = campaign
      this.cloneCampaignDialog = true
    },
    openDeleteCampaignDialog(campaign) {
      this.actionName = 'deleteCampaign'
      this.selectedItem = campaign
      this.dialogTitle = 'Delete campaign'
      this.dialogSubTitle = `${campaign.name}`
      this.dialogMessage = 'Are you sure you want to delete this campaign?'
      this.confirmDialog = true
    },
    actionSuccess() {
      this[this.actionName]()
    },
    async deleteCampaign() {
      this.loadingAction = true

      const { success, message } = await deleteCampaign(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.refreshDataAfterDelete()
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadCampaigns()
    },
    async changeStatus(campaign) {
      this.loadingStatus = true

      const { success, payload, message } = await changeCampaignStatus({
        campaignId: campaign.slug,
        formData: { status: campaign['status_id'] === 1 ? 2 : 1 }
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.items = changeItemsAfterUpdate(this.items, payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadCampaigns()
    },
    copyId(item) {
      copyInStash(item.slug)
      this.$notify({ type: 'info-bg', text: 'Copy successful' })
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadCampaigns()
      },
    },
  },
}
</script>
