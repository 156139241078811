<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="600px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Clone Campaign
          </div>
        </v-col>
      </v-row>
      <v-form class="pb-10" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="cloneCampaignName"
            ></portal-target>
          </v-col>

          <v-col class="pb-8 pb-sm-10" cols="12" sm="7">
            <FormField
              v-model="formData.name"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="cloneCampaignName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>

        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Clone</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { cloneCampaign } from '@/api/campaign-routes'

export default {
  name: 'DialogCloneCampaign',
  inheritAttrs: false,
  components: { FormField, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    campaign: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      name: null,
      slug: null,
    },
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.loading = true

        const { success, payload, message } = await cloneCampaign(this.formData)

        if (success) {
          this.$router.push({ name: 'DetailCampaign', params: { slug: payload.slug }})
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.formData.name = this.campaign.name + ' (clone)'
        this.formData.slug = this.campaign.slug
      } else {
        this.rules = []
        this.$refs.form.reset()
        this.loading = false
      }
    }
  }
}
</script>